'use client';

import {
  Bars3BottomLeftIcon,
  UserCircleIcon,
} from '@heroicons/react/24/outline';
import {
  Button,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownTrigger,
} from '@nextui-org/react';
import Link from 'next/link';
import { signOut, useSession } from 'next-auth/react';
import { LazyLoadImage } from 'react-lazy-load-image-component';

import { useMe } from '@/hooks/queries/useMe';

import NavbarItem from './navbar-item';

const navbarList = [
  { link: '/', name: 'Home' },
  { link: '/genres', name: 'Genres' },
];

const Navbar = ({
  setIsOpen,
  setIsConfirmSign,
}: {
  setIsOpen: (isOpen: boolean) => void;
  setIsConfirmSign: (isConfirmSign: boolean) => void;
}) => {
  const { data: session } = useSession();
  const { data: me } = useMe(session?.access);

  return (
    <div className="z-40 mx-auto flex h-20 w-full flex-row items-center gap-x-5 bg-transparent px-4 lg:px-16">
      <div className="flex h-full w-1/2 flex-row items-center gap-x-5 lg:w-1/3">
        <Button
          className="block bg-transparent text-black hover:text-aerospace lg:hidden"
          isIconOnly
          onClick={() => setIsOpen(true)}
        >
          <Bars3BottomLeftIcon width={30} height={30} />
        </Button>
        <Link href="/" className="cursor-pointer">
          <LazyLoadImage
            src="/images/svg/indiemunkey-color.svg"
            className="mt-2 w-40 text-center"
            effect="blur"
          />
        </Link>
      </div>
      <div className="flex h-full w-1/2 flex-row items-center justify-end gap-x-7 lg:w-2/3">
        <div className="hidden flex-row items-center gap-x-4 lg:flex">
          {navbarList.map((item) => (
            <NavbarItem item={item} key={item.link} />
          ))}
        </div>
        <div className="flex flex-row items-center gap-x-3">
          <Dropdown>
            <DropdownTrigger>
              {session?.user && me?.data ? (
                <Button
                  className="border border-gray-500 bg-red-50 text-black hover:text-aerospace"
                  isIconOnly
                  radius="full"
                >
                  <LazyLoadImage
                    src={
                      me.data.avatarFile
                        ? me.data.avatarFile.url
                        : '/favicon.ico'
                    }
                    aria-label="avatar default"
                    effect="blur"
                    className="w-full scale-100 rounded-full border-gray-500"
                  />
                </Button>
              ) : (
                <Button
                  className="bg-transparent text-black hover:text-aerospace"
                  isIconOnly
                >
                  <UserCircleIcon width={30} height={30} />
                </Button>
              )}
            </DropdownTrigger>
            {session && me?.data ? (
              <DropdownMenu
                aria-label="Static Actions"
                disabledKeys={['profile']}
              >
                <DropdownItem
                  key="profile"
                  isReadOnly
                  href={`/user/${session.user.userName}`}
                  className="rounded-none border-b opacity-100"
                >
                  <div className="flex flex-row items-center gap-x-2">
                    <div className="h-10 w-10 scale-100 overflow-hidden rounded-full border border-gray-500">
                      <LazyLoadImage
                        src={
                          me?.data.avatarFile
                            ? me.data.avatarFile.url
                            : '/favicon.ico'
                        }
                        className="w-full scale-100"
                        aria-label="aratar user"
                        effect="blur"
                      />
                    </div>
                    <div className="flex max-w-24 flex-col gap-y-1">
                      <p className="line-clamp-1 text-base font-bold tracking-wide">
                        {session.user.userName.replace(/@/g, '')}
                      </p>
                      <p className="line-clamp-1 text-sm font-normal text-gray-500">
                        {session.user.userName}
                      </p>
                    </div>
                  </div>
                </DropdownItem>

                <DropdownItem
                  key="publish-manga"
                  textValue="Publish manga"
                  href="/public-title"
                  className="mt-1"
                >
                  Publish title
                </DropdownItem>
                <DropdownItem
                  key="setting"
                  textValue="Setting"
                  href="/settings"
                >
                  Settings
                </DropdownItem>
                <DropdownItem
                  key="sign-out"
                  textValue="Sign out"
                  onClick={() => signOut()}
                >
                  Sign out
                </DropdownItem>
              </DropdownMenu>
            ) : (
              <DropdownMenu aria-label="Static Actions">
                <DropdownItem
                  key="manga"
                  onClick={() => setIsConfirmSign(true)}
                >
                  Publish title
                </DropdownItem>
                <DropdownItem key="sign-in" textValue="Sign-in" href="/sign-in">
                  Sign in
                </DropdownItem>
              </DropdownMenu>
            )}
          </Dropdown>
        </div>
      </div>
    </div>
  );
};

export default Navbar;

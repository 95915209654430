'use client';

import clsx from 'clsx';
import dayjs from 'dayjs';
import { signOut, useSession } from 'next-auth/react';
import { useEffect, useState } from 'react';

import ConfirmSignInModal from '@/components/comic/auth/confirm-sign-in-modal';
import Loading from '@/components/comic/loading';
import BackToTopButton from '@/components/layout/back-to-top-button';
import Footer from '@/components/layout/footer';
import Navbar from '@/components/layout/navbar';
import NavbarModal from '@/components/layout/navbar/navbar-modal';
import { useLayout } from '@/components/providers/LayoutProvider';

export default function DefaultLayout({
  children,
}: {
  children: React.ReactNode;
}) {
  const { loading } = useLayout();
  const { data: session, status } = useSession();
  const [isScrolled, setIsScrolled] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [isConfirmSign, setIsConfirmSign] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 0) {
        setIsScrolled(true);
      } else {
        setIsScrolled(false);
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  useEffect(() => {
    if (
      status === 'authenticated' &&
      (!session.access || dayjs().isAfter(dayjs(session.expires)))
    ) {
      signOut();
    }
  }, [session, status]);

  return (
    <>
      <div className="h-full w-full overflow-hidden">
        <div
          className={clsx(
            'fixed left-0 top-0 z-30 w-full overflow-hidden py-1 drop-shadow-sm',
            isScrolled ? 'bg-white/95 backdrop-blur' : 'bg-white'
          )}
        >
          <Navbar setIsOpen={setIsOpen} setIsConfirmSign={setIsConfirmSign} />
        </div>
        <div className="z-20 min-h-screen w-screen overflow-x-hidden">
          {children}
        </div>
      </div>
      <BackToTopButton />
      <Footer />
      <NavbarModal isOpen={isOpen} setIsOpen={setIsOpen} />
      <ConfirmSignInModal
        title="Confirm"
        description="Sign in to use"
        setIsOpen={setIsConfirmSign}
        isOpen={isConfirmSign}
      />
      <Loading loading={loading} />
    </>
  );
}
